.footer {
  margin: auto 0 2rem;
}

.footer svg {
  width: 1rem;
  height: auto;
  margin: 0 5px;
}

.footer svg.heart {
  color: var(--red);
}

.footer p {
  display: flex;
  justify-content: center;
  margin: 0 auto;
  font-weight: bold;
  color: rgba(0,0,0,0.45);
}

.footer p i {
  width: 18px;
  height: 100%;
}

.footer .social-links {
  display: flex;
  align-items: center;
  justify-content: center;
  margin-top: 30px;
}

.footer .social-links li {
  margin: 0 8px;
}

.footer .social-links .social {
  color: var(--textNormalDark);
  font-size: 1.5rem;
}

.footer .social-links .social:hover {
  color: var(--blue)
}

.footer p i.red {
  color: var(--red);
}

@media screen and (max-width: 415px) {
  .footer p {
      font-size: .9rem;
      width: 200px;
      line-break: auto;
      flex-wrap: wrap;
  }
}

@media screen and (min-width: 570px) {
  .footer .social-links {
    display: none;
  }
}
